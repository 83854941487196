.addposition {
  background-color: #ebedfb;
  height: 100vh;
  overflow-x: auto;
  -ms-overflow-style: none;
}

.addposition::-webkit-scrollbar {
  display: none;
}

.position-form {
  margin: auto;
}

.btn-primary {
  color: black;
  &.-position {
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    max-width: 200px;
    margin-right: 30px;
    height: 40px;
    margin-top: 9px;
    padding: 0;
    outline: none;
    text-transform: none;
  }

  &.-round {
    border-radius: 50px;
  }

  &.-blackborder {
    border: 2px solid #000;
  }

  &.-outlined {
    background: transparent;
    line-height: 16px;
  }
  &.-outlined-delete {
    line-height: 16px;
  }
  &.-add-requirement {
    font-size: 14px;
    font-weight: 600;
    width: 50%;
    max-width: 100px;

    height: 40px;
    margin-top: 9px;
    padding: 0;
    outline: none;
    text-transform: none;
  }
}

.btn-primary:hover {
  background-color: #4f52a3;
}

@media (min-width: 1024px) {
  .position-form {
    width: 60%;
  }
}

.requirement-list,
.bonus-list {
  text-align: left;
  vertical-align: middle;
}

.requirement {
  margin: auto;
}

.card {
  width: 75%;
  margin: auto;
}
