.apply-position {
    background-color: $white;

    input, textarea, select {
        background-color: $color-lighter;
    }
    .attach {
        display: none;
  
        &.-error {
          border: 1px solid #FF6161;
        }
      }
      .file-name {
        margin-left: 20px;
        color: #ffffff;
        &.-center {
          display: flex;
        justify-content: center;
        }
      }
}