.rocketHolder{
    position: relative;

        img.rocket{
            width: 40px;
            position: absolute;
            height: auto;
            left: 0;
            right: 0;
            margin: auto;

        }
}

@media (max-width: 425px) {
    .rocketHolder{
        position: relative;
        img.rocket{
            width: 20px;
            position: absolute;
            height: auto;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
  }