#our-values{
  .description-block{
    h2{
      text-align: left;
    }
    h3{
      text-align: left;
    }
  }
}


/* Small devices (landscape phones, 576px and up) */
@media (max-width: 425px) {
  .-padding-0-phone{
    padding: 0px;
  }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  #our-values{
    .description-block{
      h2{
        text-align: center;
      }
      h3{
        text-align: center;
      }
    }
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}