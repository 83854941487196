.careers {
    .careers-slide {
        // margin-left: 50px;
        width: auto;
        @media only screen  and (min-width : 992px) {
            margin: 0 30px 20px 30px;
        }
        display: flex;
        align-items: center;
        @media only screen  and (min-width : 1224px) {
            justify-content: center;
        }
        overflow-x: auto;
        overflow-y: hidden;
        white-space:nowrap;
        
        >.thumbnail {
            height: 750px;
            border-radius: 12px;
            @media only screen  and (min-width : 992px) {
                margin: 5px;
            }
            display: block;
        }
        .instagram-media {
            // min-width: 25% !important;
        }
    }
    .apply-section{
        margin-bottom: 50px;
    }
    .-defaultheight {
        height: 24px;
    }
}
@import 'apply';
